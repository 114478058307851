h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

.required-asterisk {
  color: #fc8181;
}

.landing-greeting {
  font-family: "Sacramento", cursive !important;
  font-size: 5rem;
  font-style: bold;
}

.bio {
  font-size: 1.5rem;
}

html {
  scroll-padding-top: 80px;
}

.github-icon,
.globe-icon {
  padding-right: 0.5rem;
}

.card-links {
  text-decoration: underline !important;
}

.card-links:hover {
  text-decoration: none !important;
}

.manage-cookies-button {
  background-color: #649379 !important;
  color: white !important;
  border-radius: 2px !important;
  padding: 0.5rem 1rem !important;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
